<template>
  <div class="shift-list">
    <div class="shift-list__item" v-for="shift in shifts" :key="shift.id">
      <div class="shift-list__block">
        <div class="shift-list__name">{{ shift.name }}</div>
        <div class="shift-list__row">
          <b>Срок подачи заявок:</b> с {{ shift.date_start_app }} по {{ shift.date_end_app }}
        </div>
        <div class="shift-list__row">
          <b>Срок проведения мероприятия:</b> с {{ shift.date_start }} по {{ shift.date_end }}
        </div>
        <router-link
          v-if="!is_view_invite && shift.application_count !== 0"
          :to="{ name: 'applications-list', params: { dir: shift.id } }"
          class="shift-list__count"
        >
          {{ shift.application_count }} активных заявок
        </router-link>
      </div>
      <div class="shift-list__block">
        <div class="shift-list__controls">
          <app-button v-if="shift.can_applicate" @click="onInviteClick(shift.id)" size="small">
            Принять участие
          </app-button>
<!--          <router-link-->
<!--            v-if="is_view_invite"-->
<!--            :to="{ name: 'application-create', params: { dir: shift.id } }"-->
<!--            class="button button&#45;&#45;mini"-->
<!--          >-->
<!--            Принять участие-->
<!--          </router-link>-->
          <router-link
            v-if="!is_view_invite"
            :to="{ name: 'direction-edit', params: { dir: shift.id } }"
            class="button button--mini"
          >
            Редактировать направление
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShiftList',
  props: ['shifts', 'is_view_invite'],
  methods: {
    onInviteClick(shift_id) {
      this.$emit('openInvitePopup', shift_id)
      // this.$store.dispatch('events/applications/GET_DATA', shift_id)
      //   .then(() => {
      //     this.$router.push({ name: 'application-create', params: { dir: shift_id } })
      //   })
      //   .catch(error => {
      //     this.$notify({
      //       type: 'warn',
      //       title: 'Внимание!',
      //       text: error.response.data.detail
      //     })
      //   })

    }
  }
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.shift-list
  display: grid
  grid-template: auto / repeat(1, 1fr)
  grid-gap: 20px

.shift-list__item
  display: flex
  justify-content: space-between
  padding: 25px
  background-color: #f8fdff
  box-shadow: 0 0 4px rgba(151, 151, 151, 0.25)
  border-radius: 10px

.shift-list__block:last-child
  flex-shrink: 0

.shift-list__name
  display: flex
  align-items: center
  margin-bottom: 15px
  font-size: 24px
  font-weight: 600

  &:before
    content: ''
    flex-shrink: 0
    display: block
    width: 36px
    height: 36px
    margin-right: 18px
    border-radius: 50%
    background-color: $color-theme
    background-image: url("~@/assets/img/start.svg")
    background-repeat: no-repeat
    background-position: center

.shift-list__row
  margin-bottom: 10px
  font-size: 16px

  b
    font-weight: 600

.shift-list__count
  color: $color-theme
  font-size: 16px
  font-weight: 600
  text-decoration: none
  transition: opacity 0.3s

  &:hover
    opacity: 0.8

.shift-list__controls a
  margin-left: 15px

</style>