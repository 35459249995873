<template>
  <div class="content">
    <app-cells position="start">
      <router-link
        :to="{ name: 'events-list' }"
        class="btn btn--link"
      >
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
        </svg>
      </router-link>
      <h1 class="title title--big title--theme">{{ event.name }}</h1>
    </app-cells>
    <div class="event-detail">
      <div class="event-detail__info">
        <h2 class="content__title title">Информация о мероприятии</h2>
        <div class="event-detail__part event-detail__part--flex">
          <div class="event-detail__col">
            <div class="event-detail__row">
              <b>Формат проведения:</b>{{ event.participation_form_name }}
            </div>
            <div class="event-detail__row">
              <b>Тип мероприятия:</b>{{ event.type_name }}
            </div>
          </div>
          <div class="event-detail__col">
            <div class="event-detail__row">
              <b>Дата проведения:</b>{{ event.date_start }} - {{ event.date_end }}
            </div>
            <div class="event-detail__row">
              <b>Организатор:</b>{{ event.organizer }}
            </div>
          </div>
        </div>
        <div class="event-detail__part">
          <div class="event-detail__row">
            <b>Регион проведения:</b>{{ event.region_text }}
          </div>
        </div>
        <h2 class="content__title title">Описание мероприятия</h2>
        <div v-html="event.desc" class="content__paragraph"></div>
      </div>
      <div class="event-detail__img">
        <img v-if="event.crop_url" :src="event.crop_url" alt="Event logo">
        <img v-else src="@/assets/img/events/event-slug.svg" alt="Event slug">
      </div>
    </div>
    <h2 class="content__title title">Контакты</h2>
    <div class="event-detail event-detail--group">
      <div class="event-detail__part" v-for="(contact, index) in event.contacts" :key="index">
        <div class="event-detail__contact">
          <img src="@/assets/img/sidebar/user-icon.svg" alt="Person icon">
          <span>{{ contact.fio }}</span>
        </div>
        <div v-if="contact.phone" class="event-detail__contact">
          <img src="@/assets/img/sidebar/phone-icon.svg" alt="Phone icon">
          <a :href="`tel:${contact.phone}`">{{ contact.phone }}</a>
        </div>
        <div class="event-detail__contact">
          <img src="@/assets/img/sidebar/link-icon.svg" alt="Email icon">
          <a :href="`mailto:${contact.email}`">{{ contact.email }}</a>
        </div>
      </div>
    </div>
    <h2 class="content__title title">Направления</h2>
    <ShiftList :shifts="event.shifts" :is_view_invite="true" @openInvitePopup="openInvitePopup" />
    <modal
      name="invite-modal"
      classes="modal-not-overflow"
      :width="900"
      :height="'auto'"
      :scrollable="true"
      :adaptive="true"
    >
      <div class="invite-modal">
        <button @click="$modal.hide('invite-modal')" type="button" class="invite-modal__close">
          <img src="@/assets/img/cancel-icon.svg" alt="">
        </button>
        <div class="invite-modal__title">Принять участие</div>
        <div class="invite-modal__content">
          <div class="invite-modal__cards">
            <button
              @click="onCheckIndividual"
              class="invite-modal__item"
              :class="{ 'invite-modal__item--active': current_type === 'ind' }"
              type="button"
            >
              <span class="invite-modal__img">
                <img src="@/assets/img/team/individual.svg" alt="">
              </span>
              <span class="invite-modal__text">Индивидуальное участие</span>
            </button>
            <button
              @click="onCheckMemberTeams"
              class="invite-modal__item"
              :class="{ 'invite-modal__item--active': current_type === 'team' }"
              type="button"
            >
              <span class="invite-modal__img">
                <img src="@/assets/img/team/collective.svg" alt="">
              </span>
              <span class="invite-modal__text">Коллективное участие</span>
            </button>
          </div>
          <template v-if="current_type === 'team'">
            <template v-if="teams && teams.length">
              <div class="invite-modal__form">
                <app-form-group label="Выберите команду" required>
                  <v-select
                    @input="onTeamChecked"
                    v-model="team"
                    :reduce="team => team.id"
                    :options="teams"
                    :searchable="false"
                    label="name"
                    placeholder="Выберите значение"
                    class="select"
                  >
                    <template slot="open-indicator">
                      <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                        <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                      </svg>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="select__item">{{ option.name }}</div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="d-center">{{ option.name }}</div>
                    </template>
                  </v-select>
                </app-form-group>
                <app-form-group v-if="members" label="Выберите участников" required>
                  <v-select
                    v-model="member_checked"
                    :options="members"
                    :searchable="false"
                    label="member_name"
                    placeholder="Выберите значения"
                    class="select select--multiple"
                    multiple
                  >
                    <template slot="open-indicator">
                      <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                        <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                      </svg>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="select__item">{{ option.member_name }}</div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="d-center">{{ option.member_name }}</div>
                    </template>
                  </v-select>
                </app-form-group>
                <AppCells position="start" v-if="team && member_checked && member_checked.length > 1">
                  <app-button @click="onCheckProfile" size="small">Продолжить</app-button>
                </AppCells>
              </div>
            </template>
            <template v-if="teams && !teams.length">
              <div class="invite-modal__empty">
                <img src="@/assets/img/warning-blue.svg" alt="">
                В данный момент вы не состоите ни в одной команде. Пожалуйста, создайте команду или присоединитесь к любой существующей
              </div>
            </template>
          </template>
          <div v-if="error_fields" class="invite-modal__error-fields">
            <div v-if="current_type === 'ind'" class="invite-modal__error-text">
              Некоторые данные не заполнены. Пожалуйста, заполните необходимые поля и попробуйте попытку еще раз
            </div>
            <div v-if="current_type === 'team'" class="invite-modal__error-text">
              Не у всех участников заполнены все необходимые данные. Пожалуйста, убедитесь, что у всех участников заполнены необходимые данные и повторите попытку.
            </div>
            <div v-for="(error, key) in error_fields" :key="key" class="invite-modal__error-row">
              <template>
                <span :class="{ 'green': error.status === 'OK' }">{{ current_type === 'ind' ?  'Ваши данные' : error.user }}:</span>
                <template v-if="error.status === 'error'">
                  <span v-for="(field, index) in error.error_fields" :key="index">
                    {{ field }}{{ error.error_fields.length > 1 && error.error_fields.length - 1 !== index ? ',' : '' }}
                  </span>
                </template>
                <template v-if="error.status === 'OK'">
                  <img src="@/assets/img/check-green.svg" alt="">
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import ShiftList from '@/components/ShiftList'

export default {
  name: 'EventUserDetail',
  components: {
    ShiftList,
  },
  data() {
    return {
      current_type: null,
      event: {},
      current_shift: null,
      error_fields: null,
      teams: null,
      team: null,
      members: null,
      member_checked: null
    }
  },
  mounted() {
    this.$store.dispatch('events/GET_DATA_USER', this.$route.params.id)
      .then(response => {
        this.event = response.data
      })
      .catch(error => {
        for (const key in error.response.data) {
          if (typeof error.response.data[key] === 'string') {
            this.$notify({
              type: 'error',
              text: error.response.data[key]
            })
          } else {
            this.$notify({
              type: 'error',
              text: error.response.data[key][0]
            })
          }
        }
      })
  },
  methods: {
    onTeamChecked() {
      this.member_checked = null
      this.$store.dispatch('team/GET_MEMBERS', { id: this.team, status: 'in_team' })
        .then(response => { this.members = response.data })
    },
    onCheckMemberTeams() {
      this.error_fields = null
      this.current_type = 'team'
      this.$store.dispatch('teams/GET_DATA', { page: 1, limit: 100, is_head: true })
        .then(response => { this.teams = response.data.results })
    },
    onCheckProfile() {
      this.error_fields = null
      this.$store.dispatch('events/directions/CHECK_PROFILE',
        { id: this.$route.params.id, dir: this.current_shift, data: { team: this.member_checked ? this.member_checked.map(item => item.id) : null } }
      )
        .then(res => {
          if (res.data.status === 'OK') {
            this.$router.push({ name: 'application-create',
              params: {
                dir: this.current_shift,
                team: this.team,
                members: this.member_checked
              }
            })
          }
        })
        .catch(error => {
          this.error_fields = error.response.data.error_fields
        })
    },
    openInvitePopup(shift_id) {
      this.current_shift = shift_id
      this.$modal.show('invite-modal')
    },
    onCheckIndividual() {
      this.team = null
      this.members = null
      this.member_checked = null
      this.current_type = 'ind'
      this.onCheckProfile()
    }
  }
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.event-detail
  display: flex
  justify-content: space-between
  gap: 30px
  margin-bottom: 30px

  +max-w($laptop_lg)
    flex-direction: column-reverse

.event-detail--group
  display: grid
  grid-template: auto / repeat(auto-fit, minmax(300px, 350px))
  grid-gap: 40px
  justify-content: flex-start

  .event-detail__part
    margin-bottom: 0

.event-detail__part
  margin-bottom: 20px

  &:last-child
    margin-bottom: 0

.event-detail__part--flex
  display: flex

  +max-w($mobile_md)
    flex-direction: column

.event-detail__col
  &:first-child
    margin-right: 60px

    +max-w($desktop_sm)
      margin-right: 30px

    +max-w($mobile_md)
      margin-right: 0
      margin-bottom: 20px

.event-detail__row
  margin-bottom: 20px
  font-size: 16px

  b
    margin-right: 5px
    font-weight: 600

  &:last-child
    margin-bottom: 0

.event-detail__contact
  display: flex
  align-items: center
  margin-bottom: 15px
  font-size: 16px

  img
    margin-right: 10px

  a
    color: $color-text-base
    text-decoration: none
    transition: color 0.3s

    &:hover
      color: $color-theme

  &:last-child
    margin-bottom: 0

.event-detail__img
  align-self: flex-start
  overflow: hidden
  max-width: 600px
  margin: 0 auto
  border-radius: 5px
  line-height: 0

  img
    width: 100%
</style>